export default class TokenUtils {
    TokenCookieKey = 'FootbackToken';

    GetToken(cookies) {
      if (cookies === undefined) {
        const regexPattern = `(^|;)\\s*${this.TokenCookieKey}\\s*=\\s*([^;]+)`

        return document.cookie.match(regexPattern)?.pop() || ''
      }

      return cookies.get(this.TokenCookieKey)
    }

    SetToken = (cookies, token) => cookies.set(this.TokenCookieKey, token)

    IsValidToken(cookies) {
      const token = this.GetToken(cookies)

      if (token == null || token.trim() === '') {
        return false
      }

      const sub = this.GetClaim(token, 'sub')

      return sub != null || sub !== undefined || sub.trim() !== ''
    }

    GetClaim = (token, claim) => JSON.parse(window.atob(token.split('.')[1]))[claim]

    GetRoles() {
      const regexPattern = `(^|;)\\s*${this.TokenCookieKey}\\s*=\\s*([^;]+)`
      const token = document.cookie.match(regexPattern)?.pop() || ''

      if (token != null && token.trim() !== '') {
        const roles = this.GetClaim(token, 'roles')

        return roles.split(',')
      }

      return null
    }
}
