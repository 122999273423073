<template>
  <v-app>
    <div
      v-if="!isUserAuthenticated & !isUserResetPassword "
      class="app-content-container boxed-container pa-6"
    >
      <login
        @user-authenticated="userAuthenticated"
        @user-resetpassword="userResetPassword"
      ></login>
    </div>
    <div
      v-if="isUserResetPassword"
      class="app-content-container boxed-container pa-6"
    >
      <reset-password @user-resetpassword="userPasswordReseted"></reset-password>
    </div>
    <div v-if="isUserAuthenticated & !isUserResetPassword">
      <vertical-nav-menu
        :is-drawer-open.sync="isDrawerOpen"
        :user-roles="userRoles"
      >
      </vertical-nav-menu>

      <v-app-bar
        app
        flat
        absolute
        color="transparent"
      >
        <div class="boxed-container w-full">
          <div class="d-flex align-center mx-6">
            <!-- Left Content -->
            <v-app-bar-nav-icon
              class="d-block d-lg-none me-2"
              @click="isDrawerOpen = !isDrawerOpen"
            ></v-app-bar-nav-icon>
          </div>
        </div>
      </v-app-bar>

      <v-main>
        <div class="app-content-container boxed-container pa-6">
          <slot></slot>
        </div>
      </v-main>

      <v-footer
        app
        inset
        color="transparent"
        absolute
        height="56"
        class="px-0"
      >
        <div class="boxed-container w-full">
          <div class="mx-6 d-flex justify-space-between">
            <span>
              &copy; 2023
              <a
                href="#"
                class="text-decoration-none"
                target="_blank"
              >RiViTech</a></span>
            <v-btn @click="logout">
              Sair
            </v-btn>
          </div>
        </div>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import Login from '@/views/Authentication/Login.vue'
import ResetPassword from '@/views/Authentication/ResetPassword.vue'

export default {
  components: {
    VerticalNavMenu,
    Login,
    ResetPassword,
  },
  created() {
    this.isUserAuthenticated = this.tokenUtils.IsValidToken(this.$cookies)

    if (this.isUserAuthenticated) {
      const token = this.tokenUtils.GetToken(this.$cookies)

      this.userRoles = this.tokenUtils.GetClaim(token, 'roles').split(',')
    }
  },
  methods: {
    userAuthenticated() {
      window.location.pathname = '/'
      this.isUserAuthenticated = true
    },
    userResetPassword() {
      // window.location.pathname = '/resetpassword'
      this.isUserResetPassword = true
    },
    userPasswordReseted() {
      this.isUserResetPassword = false
      this.userAuthenticated()
    },
    logout() {
      this.tokenUtils.SetToken(this.$cookies, '')
      window.location.pathname = '/'
    },
  },
  setup() {
    const isUserAuthenticated = ref(false)
    const isUserResetPassword = ref(false)
    const isDrawerOpen = ref(null)
    const userRoles = ref([])

    const tokenUtils = inject('tokenutils')

    return {
      isDrawerOpen,
      isUserAuthenticated,
      isUserResetPassword,
      tokenUtils,
      userRoles,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
