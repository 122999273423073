export default class DateUtils {
  OFFSETCALC = 60 * 1000

  dateOffsetToString(dateStr) {
    const newDate = new Date(dateStr)
    const offset = newDate.getTimezoneOffset()
    const date = new Date(newDate.getTime() - (offset * this.OFFSETCALC))

    return date.toISOString().split('T')[0]
  }
}
