const { saveAs } = require('file-saver')

export default class DocumentsService {
  APIURL = process.env.VUE_APP_BASE_URL;

  async downloadAllDocuments(personId, filename) {
    fetch(`${this.APIURL}\\api\\person\\${personId}\\documents\\all`)
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, `${filename}.zip`)
      })
  }

  async approveDocument(personId, documentId) {
    this.approvalRequest(personId, documentId, 'approved')
  }

  async declineDocument(personId, documentId) {
    this.approvalRequest(personId, documentId, 'declined')
  }

  async downloadDocument(personId, documentId, filename) {
    fetch(`${this.APIURL}\\api\\person\\${personId}\\documents\\${documentId}`)
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, `${filename}.jpeg`)
      })
  }

  async approvalRequest(personId, documentId, approvalStatus) {
    const approvalDto = {
      status: approvalStatus,
    }

    return fetch(`${this.APIURL}\\api\\person\\${personId}\\documents\\${documentId}\\approval`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(approvalDto),
    })
  }
}
