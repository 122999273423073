import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import TokenUtils from '../shared/utils/token.utils'

Vue.use(VueCookies)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/players',
    name: 'players',
    meta: {
      available_roles: ['Admin', 'ClubManagement'],
    },
    component: () => import('@/views/pages/Players.vue'),
  },
  {
    path: '/ClubManagement',
    name: 'clubmanagement',
    meta: {
      available_roles: ['Admin'],
    },
    component: () => import('@/views/pages/ClubManagement.vue'),
  },
  {
    path: '/associates',
    name: 'associates',
    meta: {
      available_roles: ['Admin'],
    },
    component: () => import('@/views/pages/Associates.vue'),
  },
  {
    path: '/equipments',
    name: 'equipments',
    meta: {
      available_roles: ['Admin', 'ClubManagement', 'Player'],
    },
    component: () => import('@/views/pages/EquipmentsCognitoForm.vue'),
  },
  {
    path: '/imports',
    name: 'imports',
    meta: {
      available_roles: ['Admin', 'ClubManagement'],
    },
    component: () => import('@/views/pages/Imports.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Authentication/Login.vue'),
  },
  {
    path: '/resetpassword',
    name: 'resetpassword',
    component: () => import('@/views/Authentication/ResetPassword.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/account-settings/AccountSettings.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const tokenUtils = new TokenUtils()
const userRoles = tokenUtils.GetRoles()

router.beforeEach((to, from, next) => {
  if (userRoles == null) {
    next()
  }

  if (to.meta.available_roles != null) {
    if (userRoles.some(role => to.meta.available_roles.includes(role))) {
      next()
    } else {
      next({ path: '/' })
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
