export default class AssociateService {
  APIURL = process.env.VUE_APP_BASE_URL;

  getParsedDate = date => date.substring(0, 10)

  async getAsscoiates() {
    const response = await fetch(`${this.APIURL}\\api\\AssociateMember`)
    const responsejson = await response.json()

    const mappedResponse = responsejson.map(element => this.mapAssociate(element))

    return mappedResponse
  }

  mapAssociate(response) {
    return {
      clubLevel: response.clubLevel,
      id: response.id,
      pictureUrl: response.pictureUrl,
      age: response.age,
      name: response.name,
      gender: response.gender,
      email: response.email,
      phone: response.phone,
      address: response.address,
      birthDate: this.getParsedDate(response.birthDate),
      playerNumber: response.playerNumber,
      fullName: response.fullName,
      displayName: response.displayName,
      isSelected: false,
      signDate: this.getParsedDate(response.signDate),
      isForeverAssociate: response.isForeverAssociate,
      internalId: response.internalId,
      isPaid: response.isPaid,
      lastIsPaid: response.lastIsPaid,
    }
  }

  async updateAssociate(associateId, associateInformation) {
    const associateDto = {
      pictureUrl: '',
      gender: associateInformation.gender,
      email: associateInformation.email,
      phone: associateInformation.phone,
      address: associateInformation.address,
      birthDate: associateInformation.birthDate,
      fullName: associateInformation.fullName,
      displayName: associateInformation.displayName,
      signDate: associateInformation.signDate,
      isForeverAssociate: associateInformation.isForeverAssociate,
      internalId: associateInformation.internalId,
    }

    const response = await fetch(`${this.APIURL}\\api\\AssociateMember\\${associateId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(associateDto),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return true
  }

  async pay(associateId, associatePayments) {
    const associatePaymentsDto = []

    associatePayments.forEach(element => {
      associatePaymentsDto.push({
        associatePaymentYearId: element.associatePaymentYearId,
        associatePaymentYear: element.associatePaymentYear,
        isPaid: true,
      })
    })

    const response = await fetch(`${this.APIURL}\\api\\AssociateMember\\${associateId}\\payments`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(associatePaymentsDto),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return true
  }

  async deletePlayer(associateId) {
    const response = await fetch(`${this.APIURL}\\api\\AssociateMember\\${associateId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return true
  }
}
