export default class ApiService {
  APIURL = process.env.VUE_APP_BASE_URL;

  async get(requestPath) {
    return this.sendRequest(requestPath)
  }

  async sendRequest(requestUrl) {
    const response = await fetch(`${this.APIURL}\\${requestUrl}`)

    return response.json()
  }
}
