<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { computed, provide } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import ApiService from './shared/api.service'
import PlayerService from './shared/player.service'
import AssociateService from './shared/associates.service'
import OrdersService from './shared/orders.service'
import UserService from './shared/user.service'
import OrdersLegacyService from './shared/orders_legacy.service'
import DocumentsService from './shared/documents.service'
import FiltersService from './shared/filters.service'
import DateUtils from './shared/utils/DateUtils'
import TokenUtils from './shared/utils/token.utils'
import Login from '@/views/Authentication/Login.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    Login,
  },
  setup() {
    const { route } = useRouter()

    provide('apiservice', new ApiService())
    provide('filtersservice', new FiltersService())
    provide('playerservice', new PlayerService())
    provide('associateservice', new AssociateService())
    provide('ordersservice', new OrdersService())
    provide('userservice', new UserService())
    provide('orderslegacyservice', new OrdersLegacyService())
    provide('dateutils', new DateUtils())
    provide('tokenutils', new TokenUtils())
    provide('documentsservice', new DocumentsService())

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>
