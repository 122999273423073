<template>
  <v-container
    fluid
    style="padding: 10px;"
  >
    <!-- Logo row -->
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <!-- Add your logo here using v-img or img -->
        <v-img
          :src="require('@/assets/images/logos/logofutbacknew.svg')"
          alt="Your Logo"
        ></v-img>
      </v-col>
    </v-row>

    <!-- Login form -->
    <v-row>
      <v-col
        cols="12"
        class="text-center"
      >
        <v-form>
          <!-- Username row -->
          <v-row>
            <v-col
              cols="12"
            >
              <v-flex class="row-height">
                <v-text-field
                  id="username"
                  v-model="username"
                  outlined
                  dense
                  placeholder="Utilizador"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-col>
          </v-row>

          <!-- Password row -->
          <v-row>
            <v-col
              cols="12"
            >
              <v-flex class="row-height">
                <v-text-field
                  id="password"
                  v-model="password"
                  :append-icon="showPassword ? icons.mdiEyeOff : icons.mdiEye"
                  :type="showPassword ? 'text' : 'password'"
                  outlined
                  dense
                  placeholder="Password"
                  hide-details
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-flex>
            </v-col>
          </v-row>

          <!-- Buttons row -->
          <v-row>
            <v-col
              cols="12"
            >
              <v-flex class="row-height">
                <v-btn
                  color="primary"
                  @click="submit"
                >
                  Entrar
                </v-btn>
              </v-flex>
            </v-col>
            <v-col
              cols="12"
            >
              <!-- Explicitly set the width and background color for the divider -->
              <v-divider
                class="border-opacity-75"
                style="width: 100%; background-color: #e0e0e0;"
              ></v-divider>
              <br>
              <v-divider
                class="border-opacity-75"
                style="width: 100%; background-color: #e0e0e0;"
              ></v-divider>
            </v-col>
            <v-col
              cols="12"
            >
              <v-flex class="row-height">
                <v-btn
                  color="secondary"
                  href="https://www.cognitoforms.com/Footback1/AcessoIrivoFutebolApp"
                  target="_blank"
                >
                  Registar
                </v-btn>
              </v-flex>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ref, inject } from '@vue/composition-api'
import {
  mdiEyeOff,
  mdiEye,
} from '@mdi/js'

let userService
let tokenUtils

export default {
  setup() {
    const username = ref('')
    const password = ref('')
    const showPassword = ref(true)
    userService = inject('userservice')
    tokenUtils = inject('tokenutils')

    return {
      username,
      password,
      showPassword,
      icons: {
        mdiEyeOff,
        mdiEye,
      },
    }
  },
  methods: {
    async submit() {
      userService
        .authenticateUser(this.username, this.password)
        .then(token => {
          if (token !== 'RESETPASSWORD') {
            this.$emit('user-authenticated', token)
            tokenUtils.SetToken(this.$cookies, token)
          } else {
            this.$emit('user-resetpassword', token)
          }
        })
        .catch(error => {
          alert('wrong credentials') // eslint-disable-line no-alert
          console.log(error)
          throw error
        })
    },
  },
}
</script>
