export default class OrdersLegacyService {
  APIURL = process.env.VUE_APP_BASE_URL;

  async get(personId) {
    const response = await fetch(`${this.APIURL}\\api\\legacyOrders\\${personId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const parsedResponse = await response.json()

    const mappedResponse = parsedResponse.map(r => this.mapOrders(r))

    return mappedResponse
  }

  async getClubLevelOrders(personId, clubLevel) {
    const response = await fetch(`${this.APIURL}/api/legacyorders/-1?clubLevels=${clubLevel}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const parsedResponse = await response.json()

    const mappedResponse = parsedResponse.map(r => this.mapOrders(r))

    return mappedResponse
  }

  mapOrders = function mapOrders(response) {
    const clonedResponse = JSON.parse(JSON.stringify(response))

    if (response.price === response.paidAmount) {
      clonedResponse.isPaid = true
    } else {
      clonedResponse.isPaid = false
    }

    return clonedResponse
  }
}
