<template>
  <v-form>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="userName"
          v-model="userName"
          outlined
          dense
          placeholder="Username"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="password"
          v-model="password"
          type="password"
          outlined
          dense
          placeholder="Old Password"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="newPassword"
          v-model="newPassword"
          type="password"
          outlined
          dense
          placeholder="New Password"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-text-field
          id="confirmNewPassword"
          v-model="confirmNewPassword"
          type="password"
          outlined
          dense
          placeholder="Confirm New Password"
          hide-details
        ></v-text-field>
      </v-col>

      <v-col
        offset-md="3"
        cols="12"
      >
        <v-btn
          color="primary"
          @click="submit"
        >
          Submit
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref, inject } from '@vue/composition-api'

let userService
let tokenUtils

export default {
  setup() {
    const userName = ref('')
    const password = ref('')
    const newPassword = ref('')
    const confirmNewPassword = ref('')

    userService = inject('userservice')
    tokenUtils = inject('tokenutils')

    return {
      userName,
      password,
      newPassword,
      confirmNewPassword,
    }
  },
  methods: {
    async submit() {
      if (this.newPassword !== this.confirmNewPassword) {
        alert('Passwords não coincidem.') // eslint-disable-line no-alert
      } else if (this.newPassword.length < 7) {
        alert('Passwords tem de ter mais de 7 caracteres.') // eslint-disable-line no-alert
      } else {
        userService
          .resetPassword(this.userName, this.password, this.newPassword)
          .then(token => {
            this.$emit('user-resetpassword', token)
            tokenUtils.SetToken(this.$cookies, token)
          })
          .catch(error => {
            alert('wrong credentials') // eslint-disable-line no-alert
            console.log(error)
            throw error
          })
      }
    },
  },
}
</script>
