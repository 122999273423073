const waitingPickUp = 'WaitingPickUp'
const waitingOrder = 'WaitingOrder'

export default class OrdersService {
  getAssembledOrders = function getAssembledOrders(equipmentsResponse, ordersResponse) {
    const assembledResponse = []

    const ordersById = []

    ordersResponse.reduce((dictionary, province) => {
      ordersById[province.id] = province

      return null
    }, {})

    equipmentsResponse.forEach(element => {
      let orderStatus

      const newValue = element

      newValue.items = []

      element.orders.forEach(elementOrder => {
        const order = ordersById[elementOrder.orderid]

        if (orderStatus === undefined) {
          orderStatus = order.status
        }

        if (order === undefined) {
          return
        }

        if (orderStatus !== waitingPickUp) {
          if (order.status === waitingPickUp) {
            orderStatus = order.status
          }

          if (order.status === waitingOrder) {
            orderStatus = order.status
          }
        }

        order.items.forEach(orderItems => {
          element.items.push(orderItems)
        })
      })

      newValue.status = orderStatus

      assembledResponse.push(newValue)
    })

    return assembledResponse
  }
}
