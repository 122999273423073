import TokenUtils from './utils/token.utils'

const { createHash } = require('crypto')

export default class UserService {
  APIURL = process.env.VUE_APP_BASE_URL;

  getParsedDate = date => date.substring(0, 10)

  mapPerson = response => ({
    id: response.id,
    pictureUrl: response.pictureUrl,
    age: response.age,
    name: response.name,
    gender: response.gender,
    email: response.email,
    phone: response.phone,
    address: response.address,
    birthDate: this.getParsedDate(response.birthDate),
    fullName: response.fullName,
    displayName: response.displayName,
  })

  async getCurrentUserDetails() {
    const tokenUtils = new TokenUtils()

    const token = tokenUtils.GetToken()
    const personId = tokenUtils.GetClaim(token, 'person_id')

    return this.get(personId)
  }

  async get(id) {
    const response = await fetch(`${this.APIURL}\\api\\person\\${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const parsedResponse = await response.json()

    return this.mapPerson(parsedResponse)
  }

  async getScopeDetails(personId) {
    const response = await fetch(`${this.APIURL}\\api\\person\\${personId}\\scopeDetails`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const parsedResponse = await response.json()

    return parsedResponse
  }

  async save(playerId, playerInformation) {
    const playerDto = {
      pictureUrl: '',
      name: '',
      gender: playerInformation.gender,
      email: playerInformation.email,
      phone: playerInformation.phone,
      address: playerInformation.address,
      birthDate: playerInformation.birthDate,
      clubLevel: playerInformation.clubLevel,
      playerNumber: playerInformation.playerNumber,
      fullName: playerInformation.fullName,
      displayName: playerInformation.displayName,
    }

    const response = await fetch(`${this.APIURL}\\api\\person\\${playerId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(playerDto),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return true
  }

  async getDocuments(personId) {
    const response = await fetch(`${this.APIURL}\\api\\person\\${personId}\\documents`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const parsedResponse = await response.json()

    const mappedResponse = parsedResponse.map(resp => ({
      personId: resp.personId,
      documentId: resp.documentId,
      uploadStatus: resp.uploadStatus,
      description: resp.description,
      isFileUploaded: resp.uploadStatus !== 'NotDelivered',
    }))

    return mappedResponse
  }

  async sendFile(personId, fileContent, documentTypeId) {
    const formData = new FormData()
    formData.append('file', fileContent)

    return fetch(`${this.APIURL}\\api\\person\\${personId}\\documents\\${documentTypeId}`, {
      method: 'PUT',
      body: formData,
    })
  }

  async authenticateUser(username, userpassword) {
    const authenticationDto = {
      userName: username,
      password: this.hash(userpassword),
    }

    const response = await fetch(`${this.APIURL}\\api\\authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(authenticationDto),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const parsedResponse = await response.text()

    return parsedResponse
  }

  hash = string => createHash('sha256').update(string).digest('hex')

  async resetPassword(username, userpassword, confirmpassword) {
    const resetDto = {
      userName: username,
      password: this.hash(userpassword),
      newPassword: this.hash(confirmpassword),
    }
    const response = await fetch(`${this.APIURL}\\api\\authenticate\\reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resetDto),
    })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const parsedResponse = await response.text()

    return parsedResponse
  }
}
