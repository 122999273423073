export default class PlayerService {
  APIURL = process.env.VUE_APP_BASE_URL;

  async get(playerId) {
    if (playerId == null) {
      return Promise.resolve()
    }

    const response = await fetch(`${this.APIURL}\\api\\Player?playerId=${playerId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const parsedResponse = await response.json()

    return parsedResponse
  }

  async updatePlayer(playerId, playerInformation) {
    const playerDto = {
      pictureUrl: '',
      name: '',
      gender: playerInformation.gender,
      email: playerInformation.email,
      phone: playerInformation.phone,
      address: playerInformation.address,
      birthDate: playerInformation.birthDate,
      clubLevel: playerInformation.clubLevel,
      playerNumber: playerInformation.playerNumber,
      fullName: playerInformation.fullName,
      displayName: playerInformation.displayName,
    }

    const response = await fetch(`${this.APIURL}\\api\\player\\${playerId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(playerDto),
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return true
  }

  async deletePlayer(playerId) {
    const response = await fetch(`${this.APIURL}\\api\\player\\${playerId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    return true
  }
}
